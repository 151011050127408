.side-menu {

  .close-icon {
    cursor: pointer;
    ion-icon {
      font-size: 36px !important;
    }
  }

  .icon-larger {
    font-size: 36px !important;
  }

  .menu-button {
    text-transform: none;
    font-size: 12px;
    font-weight: 600;
  }

  .large-item {
    font-size: 40px;
    display: block;
    width: 100%;
    cursor: pointer;
    line-height: 64px;
  }

  .small-item {
    font-size: 20px;
    display: block;
    width: 100%;
    cursor: pointer;
    line-height: 36px;
    
    &::part(native) {
      border-bottom: none;
    }
  }

  .list-container {
    margin-top: 100px;
  }

  .page-header {
    padding: 10px;
    display: flex;
  }

  ion-button {
    width: 100px;
    height: 45px;
    font-size: 12px;
    font-weight: 600;
  }

  ion-segment {
    --min-height: 32px;

    justify-content: flex-start;
    width: auto;
    margin-left: 1.5rem;
  }

  ion-segment-button {
    --indicator-height: 1.5px;
    --background-hover: none;
    --background-focused: none;
    --min-width: 0;
    --padding-end: 0;
    --padding-start: 0;
    --ripple-color: transparent;

    width: auto;
    flex: 0 0 auto;
    min-height: 0;
    min-width: 0;
    padding: 0;
    margin-right: 1.5rem;
    text-transform: none;
    font-size: 1rem;
    line-height: 1.5rem;
  }

}
