.explore-slider {

  .controls {
    
    .slider-timer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      ion-text {
        margin-bottom: 0;
      }

      .line {
        vertical-align: top;
        display: inline-block;
        width: 80px;
        margin-left: 12px;
        margin-right: 12px;

        .inner {
          display: block;
          margin: auto 0;
          width: 100%;
          height: 1px;
          background-color: #8f8f8f;

          .over-inner {
            display: block;
            margin: auto 0;
            width: 0;
            height: 1px;
            background-color: #ffffff;
          }
        }
      }
    }
  }
}
