.explore-announcements {
  padding: 24px;
  padding-bottom: 32px;

  .announcements-container{
    background-color: #fdf3c5;
  }

  .slide-wrapper{
    padding: 16px;
    width: 100%;
  }

  .announce-text {
    width: 100%;
    font-size: 16px;
    background-color: #fdf3c5;
    text-align: left;
    overflow: hidden;
    overflow-wrap: break-word;
    line-height: 150%;
  }

  .announce-title {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 14px;
  }

  .nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-left: 12px;
    padding-bottom: 16px;

    ion-button{
      margin: 0;
      height: auto;

      &::part(native){
        padding: 0;
      }

      &.button-disabled::part(native) {
        background-color: transparent;
        color: #000;
      }

      &.button-disabled{
        color: #000;
        opacity: .3;
      } 
    }
  }

  .nav-text {
    font-size: 16px;
    padding-left: 7px;
    padding-right: 7px;
    text-align: center;
  }
}
