.login-page{

    ion-button {
        margin-top: 30px;
        font-size: 12px;
        text-transform: none;

        ion-icon{
            margin-left: 12px;
        }

        &.ion-color-secondary{
            &::part(native) {
                border: 1px solid #000000;
                box-shadow: none;
            }
        }
    }

    h1{
        font-size: 20px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 0;

        ion-icon, span{
            display: inline-block;
            vertical-align: top;
        }

        ion-icon{
            margin-top: 2px;
        }
    }

    .error-message{
        width: 90%;
        margin: 0 auto;
        margin-top: 10px;
    }

    .support-link{
        width: 100%;
        display: block;
        margin: 0 auto;
        margin-top: 8px;
        margin-bottom: 16px;
        text-align: center;
        font-size: 14px;
    }

    ion-router-link{
        text-decoration: underline;
    }

    .brand-logo{
        max-width: 202px;
        width: 100%;
        display: block;
        margin: 0 auto; 
        margin-top: 27px;
        margin-bottom: 2px;
    }

    .brand-image{
        width: 100%;
        height: 180px;
        object-fit: cover;
    }

    .ion-margin-top {
        --border-radius: 25px;
    }

    ion-input{
        border: solid 1px lightgray;
        border-radius: 10px;

        input{
            border: 1px;
            border-width: 2px;
            color:black;
            padding-left: 13px !important;
        }
    }

    ion-label.input-label{
        color:#616161;
        padding:auto;
        padding-bottom: 7px;
        font-size: 14px;
    }

    ion-label.required-label{
        float: right;
        transform: initial !important;
    }

    ion-label.toggle-label{
        font-size: 14px;
        margin-bottom: 5px;
    }

    .input-container{
        padding-top:20px;
        display:flex;
        flex-direction: column;
    }

    .toggle-container{
        color:#616161;
        margin:auto;
        margin-top: 14px;
        margin-left:0;
        padding:0;
        display:flex;
        flex-direction: column;
    }

    ion-item{
        --padding-start:0;
        --padding-top:10px;
    }

    ion-toggle{
        padding-top: 5px;
        padding-left: 0;

        &::part(track){
            height: 24px;
            width: 48px;
        }

        &::part(handle):not(.ios){
            left: 3px;
        }
    
        &.toggle-checked{

            &::part(track){
                background-color: #000000;
            }

            &::part(handle){
                background-color: #ffffff;
            }   

            &::part(handle):not(.ios) {
                left: -3px;
            }
        }
    }

    .verify-help{
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
    }

    ion-button + ion-button {
        margin-top: 8px;
    }
}