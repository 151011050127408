.product-page{
  
  ion-breadcrumbs{
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 18px;
  }
  
  .product-detail {
    opacity: 0;
    transition: opacity .5s;

    .product-title-area{

      ion-item::part(native){
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  
    &.reveal {
      opacity: 1;
    }

    .swiper-container {
      width: 100%;
    }

    .item-details {
      padding: 0px 24px 8px;

      ion-grid{
        padding: 0;

        ion-col:first-child {
          padding-left: 0;
        }

        ion-col:last-child {
          padding-right: 0;
        }
      }
  
      ion-col{
        display: flex;

        &:first-child {
          justify-content: flex-start;
        }

        &:nth-child(2) {
          justify-content: flex-end;
        }
      }

      p {

        &.product-number {
          color: #57595A;
          font-size: 14px;
          margin: 0;
          font-weight: 400;
          text-transform: uppercase;
        }

        &.product-price {
          font-size: 14px;
          color: #000;
          margin: 0;
          text-align: right;

          span {
            display: block;
            color: #57595A;
            font-weight: 400;
          }

          strong {
            font-size: 20px;
            font-weight: 600;
            line-height: 130%;
            display: block;

            &:nth-child(3){
              font-weight: 400;
              font-size: 14px;
              color: #57595A;
              line-height: 150%;
            }
          }
        }
      }
    }

    ion-item {
      width: 100%;
    }

    .product-id {
      font-size: 14px;
      color: #808080;
      width: 70%;
    }

    .product-price {
      font-weight: bold;
      font-size: 20px;
    }

    .text-detail {
      font-size: 14px;
      color: #808080;
    }

    .title {
      width: 100%;
      font-size: 1.2em;
      padding-left: 24px;
    }

    .order-title {
      padding-top: 48px;
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 16px;

      h3 {
        margin: 0;
        font-size: 28px;
        line-height: 100%;
        font-weight: normal;
      }
    }

    .product-description {
      padding: 20px;
      background-color: #f4f4f4;
      width: 100%;
    }

    .product-description-title {
      width: 100%;
      padding: 10px 0;
      font-weight: bold;
    }

    .customize-menu {
      width: 100%;
      height: 100%;
      margin: auto;
      padding: 20px 0;

      ion-button {
        margin-bottom: 60px;
      }

      .text-description {
        padding: 20px;
        margin: auto;
        text-align: center;
        min-width: 80%;
        color: #6B7280;
        font-size: 14px;
      }

      .text-title {
        color: #1F2937;
        margin: 5px auto;
        width: 100%;
        font-size: 28px;
        font-weight: 400;
        padding: 10px;
        padding-left: 15px;
        top: 0;
      }

      .icon-container {
        margin: auto;
        width: 50px;
        height: 50px;

        ion-icon {
          width: 70px;
          height: 70px;
          margin: auto;
          position: relative;
          display: flex;
          justify-content: center;
          align-content: center;
        }
      }
    }

    .color-menu {
      display: flex;
      width: 100%;
      margin: 1rem auto;
      height: 140px;
      position: relative;
      justify-content: space-evenly;
      flex-direction: column;
      margin-bottom: 0;

      .product-thumbnail {
        min-width: 56px;
        min-height: 56px;
        max-width: 56px;
        max-height: 56px;
        margin-bottom: 8px;
      }

      .image-btn {
        min-width: 10%;
      }

      .title {
        width: 100%;
        font-size: 1.2em;
        margin-bottom: .5rem;
        padding-left: 24px;
        padding-right: 24px;
      }

      img {
        padding: 3px;
      }

      .thumbnail-items {
        margin-top: 0;
        position: static;
        padding-top: 0;
        margin-bottom: 0;
        padding-left: 20px;
      }

      .color-selection.light {
        padding-left: 24px;
        padding-right: 24px;
        color: #57595A;
        opacity: 1;
      }
    }

    .img-thumb {
      padding: 5px;
      margin: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .select-group {
      padding: 32px 48px;
      background-color: #F6F6F8;
      border-radius: 8px;
      margin-top: 8px;

      p {
        font-size: 14px;
        text-align: center;
        max-width: 230px;
        margin: 0 auto;

        strong {
          font-weight: 800;
        }
      }
    }

    .loading-groups{
      padding-left: 24px;
    }

    .group-menu {
      margin-top: 24px;

      .button-size.ion-color-primary::part(native) {
        border-color: #989B9C;
      }

      ion-buttons {
        padding-left: 20px;
        padding-right: 14px;
        flex-wrap: wrap;
        margin-top: 16px;
      }

      ion-button::part(native) {
        border: 1px solid #000;
        border-radius: 20px;
        padding: 8px 16px;
        height: 34px;
      }

      ion-button {
        margin-right: 4px;
        margin-left: 4px;
        margin-bottom: 8px;
        height: 34px;

        &.ion-color-secondary {
          &::part(native) {
            background-color: #000;
          }
        }
      }
    }

    .sizing-menu {
      width: 100%;
      position: relative;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 20px;
      margin-bottom: 36px;

      .size-items-header {
        --min-height: 0;

        &::part(native) {
          padding-left: 0;
          border: none;
          border-bottom: 4px solid #DADCDD;
        }

        ion-label {
          font-size: 12px;
          margin-bottom: 5px;
          color: #57595A;

          &.quantity-label {
            width: auto;
            margin-right: 43px;
            flex: none;
          }
        }
      }

      .size-item {

        &::part(native) {
          padding-left: 0;
          border-bottom: 1px solid #DADCDD;
        }
      }

      .text-input {
        width: 47px;
        min-width: 47px;
        max-width: 47px;
        height: 34px;
        border: 1px solid #989B9C;
        border-radius: 8px;
        text-align: center;
        margin-right: 16px;

        &.has-focus,
        &.has-value {
          color: #000;
          border-color: #000;
        }

        input {
          padding-left: 0;
          padding-right: 0;
        }


        &[disabled=true] {
          border-color: #DADCDD;
          background-color: #F6F6F8;
        }
      }

      .title {
        padding-left: 0;
        margin-bottom: 16px;
      }

      .label-size {
        font-size: 0.875rem;
        line-height: 120%;
      }
    }

    .product-title {
      padding: 0;
      font-size: 28px;
      font-weight: 400;
      margin-top: 9px;
    }

    .product-thumbnail {
      border-radius: 50%;
      border: 1px solid #000;
      background-color: #fff;
      margin: 0 5px;
      --size: 35px;
    }

    ion-row {
      width: 100%;
    }

    .thumbnail-items {
      width: 100%;
      margin: auto;
      display: flex;
      margin-bottom: 10px;
      position: absolute;
      padding-top: 10px;
      overflow-x: scroll;

      ion-button {
        flex: 0 0 auto;
      }

      ion-button:hover::part(native)::after {
        display: none;
      }
    }

    .thumbnail-wrap {
      width: auto;
      position: relative;
      max-width: 100%;
      flex-wrap: nowrap;
      white-space: nowrap;
      margin-bottom: inherit;
      padding-top: 10px;
      padding-bottom: 4px;
    }

    .thumbnail-padding {
      width: 24px;
      height: 1px;
      flex: 1 0 24px;
    }

    .image-container {
      position: relative;
      padding-bottom: 75px;
      background-color: #f4f4f4;
      width: 100%;
      min-height: 400px;

      img {
        object-fit: contain;
      }
    }

    .display-product-colors {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      margin: auto;
      position: absolute;
      bottom: 30px;
      height: 100px;
      z-index: 99;

      .color-selection {
        width: 100%;
        height: 20px;
        margin: 0 auto;
        justify-content: center;
        text-align: center;
        line-height: 1.5rem;
        font-weight: medium;
        margin-top: 30px;
        position: absolute;
        left: 0;
        bottom: 0;
        .color-code {
          font-size: 0.75rem;
          line-height: 0.875rem;
          color: #989B9C;
        }
      }
    }

    .light {
      font-size: 0.9em;
      color: #808080;
      text-align: left !important;
      padding-left: 20px;
    }

    .purchase-container {
      background-color: #000;
      width: 100%;
      padding: 24px;
      position: sticky;
      bottom: 0;
      height: 122px;
      z-index: 99;
      .pwa-focused & {
        position: static;
      }
    }

    .add-cart-btn {
      --border-radius: 20px;
      position: relative;
      margin: auto;
      display: flex;
      font-size: 12px;

      &::part(native) {
        height: 34px;
        text-transform: none;
      }
    }

    .image-btn {
      height: 100%;
      padding: 0;
      margin: 0;
      --padding-start: 5;
      --padding-end: 5;
    }

    .total-price {
      font-size: 12px;
      color: #F6F6F8;
      margin: 0;
      margin-bottom: 16px;
      display: inline-block;
      margin-right: 24px;

      &:last-child{
        margin-right: 0;
      }

      span {
        display: inline-block;
        margin-top: 5px;
        vertical-align: top;
        margin-right: 8px;
      }

      strong {
        font-size: 20px;
        font-weight: 600;
        display: inline-block;
        vertical-align: top;
      }
    }

    .bold {
      font-weight: bold;
      font-size: 1.5em;
    }

    .center {
      text-align: center;
    }

    .product-image-slider {
      position: relative;
      transition: height 200ms ease-in-out;
      min-height: 400px;
      ion-img::part(image) {
        height: var(--height);
      }
    }

    ion-button.slide-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      margin: auto;
      padding: 0;
      width: 40px;
      height: 40px;

      &::part(native) {
        border-radius: 150px;
        padding: 0;
        width: 40px;
        height: 40px;
        background-color: #202020;
      }

      &.prev-button {
        left: 24px;
      }

      &.next-button {
        right: 24px;
      }
    }
  }
}

.embellishment-display {
  width: 100%;
  margin-bottom: 28px;

  ion-label {
    width: 100%;
    display: block;
    font-size: 14px;
  }

  ion-row {
    ion-col:first-child {
      text-align: right;
    }
  }
}