.explore-brands {
  padding-left: 24px;
  margin-bottom: 32px;

  h2{
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    margin-bottom: 16px;
  }

  p{
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-decoration: underline;
    display: block;
    text-align: left;
    margin: 0;
  }

  .view-all{
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    ion-button{
      font-size: 14px;
      font-weight: 600;
    }
  }

  img{
    height: 170px !important;
    width: 100% !important;
    object-fit: cover;
  }

  .swiper-slide{

    ion-router-link{
      width: 100%;
    }
  }
}
