.explore-slider {
  background-color: #202020;
  height: 668px;

  ion-card {
    padding: 0;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    width: 100%  !important;
    display: flex !important;
    flex-direction: column  !important;
    height: 596px  !important;
  }

  &.one-slide{

    ion-card{
      height: 656px !important;
    }
  }

  ion-button.shop-button {
    color: black;
    height: 49px;
    font-weight: 100;

    &::part(native) {
      height: 49px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .cta-container {
    display: flex;
    flex-direction: row;

    ion-button{
      margin-right: 16px;
    }
  }

  .slide-content {
    display: block;
  }

  ion-card-header {
    padding: 0;
    display: flex;
    flex-grow: 1;

    ion-img, ion-router-link{
      width: 100%;
      height: 100%;
      display: block;

      &::part(image) {
        height: 100%;
        width: 100%;
        display: block;
        object-fit: cover;
      }
    }
  }

  ion-button {
    text-transform: none;
    font-weight: bold;
    --border-radius: 25px;
  }

  ion-card-header + .card-content-md {
    padding-top: 24px;
  }
  ion-card-content {
    background-color: #202020;
    text-align: left;
    padding: 24px;
    padding-bottom: 18px;
  }

  ion-title {
    text-align: left;
    padding: 0;
    font-weight: 400 !important;
    font-size: 40px !important;
    line-height: 48px;
    color: #ffffff;
    margin-bottom: 8px;
    position: static;
    align-items: flex-start;
    height: auto;
  }

  ion-text {
    line-height: 21px;
    font-weight: 14px;
    display: block;
    margin-bottom: 24px;
  }

  ion-router-link.ion-color-secondary {
    text-decoration: underline;
  }

  .text-link {
    align-items: center;
    display: flex;
  }

  .controls {
    position: relative;
    background-color: #202020;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;

    ion-text{
      line-height: 24px;
      font-weight: 700;
      font-size: 16px;
    }

    > .button {
      margin: 0;
      height: 34px;
      width: 34px;

      &::part(native) {
        padding: 0;
        height: 34px;
        width: 34px;
        border: 1px solid #ffffff;
        border-radius: 150px;
      }

      &:last-child{
        &::part(native) {
          padding-left: 5px;
        }
      }
    }

    ion-icon {
      font-size: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
