.recommended-search{
    margin-top: 115px;
    
    h2{
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        color: #525252;
    }

    ul {
        padding-left: 0;

        li{
            list-style: none;

            ion-router-link{
                color: #525252;
                font-weight: 400;
                font-size: 24px;
                line-height: 160%;
            }
        }
    }
}