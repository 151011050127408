.app-header{

  ion-toolbar{
    padding-top: 13px !important;
    padding-bottom: 13px;
    // --opacity: .9;
    // --ion-background-color: transparent;
    --background: rgba(255, 255, 255, .9);
  }

  .menu-logo{
    max-width: 108px;
    margin: 0 auto;
    display: block;
    cursor: pointer;
  }

  ion-button.semi-hidden{
    visibility: hidden;
  }

  ion-icon, ion-menu-button{
    font-size: 27px;
  }

  .cart-badge-number{
    position: absolute;
    top: 5px;
    right: 8px;
    font-size: 10px;
    font-weight: 600;
    border-radius: 150px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    line-height: 120%;
    padding-bottom: 2px;
    z-index: 999;
  }

  .custom-notification{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #E7FAE4;
    width: 100%;
    z-index: 99999;
    padding-top: 32px;
    padding-bottom: 16px;
    padding-left: 18px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.no-icon{
      padding-left: 24px;
    }

    .flex-area{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
    }

    .notification-message{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p{
      margin: 0;
      display: inline-block;
      color: #000000;
      font-size: 14px;
      font-weight: 700;
    }

    ion-button{
      display: inline-block;
      text-transform: none;
    }

    ion-icon{
      margin-right: 8px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      font-size: 56px;
    }

    .progress-bar{
      position: absolute;
      background-color: #000000;
      height: 4px;
      bottom: 0;
      left: 0;
    }
  }

  .total-discount {
    text-align: center;
    background-color: #F8D950;
    color: #000;
    padding: 7px;
    margin: 0;
    font-weight: 400;
    font-size: 14px;

    &.over{
      background-color: #AB3F1D;
      color: #ffffff;
    }
  }
}
