.cart-page{

    ion-breadcrumbs{
        padding-left: 24px;
        padding-right: 24px;
    }

    .has-bar{

        ion-breadcrumbs{
            padding-top: 31px !important;
        }
    }

    .modified-error{
        margin-top: 16px;
        margin-bottom: 16px;
        position: relative;

        p{
            padding-left: 28px;
            color: #DA1414;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            margin: 0;
        }

        ion-icon{
            position: absolute;
            left: 0;
            top: 2px;
        }

        &.empty{
            margin-top: 60px;
            padding-left: 24px;
            padding-right: 24px;

            ion-icon{
                left: 24px;
            }
        }

        + .empty-list{
            margin-top: 50px;
        }
    }

  .wrapper{
      padding-left: 24px;
      padding-right: 24px;

      ion-breadcrumbs{
        padding-left: 0;
        padding-right: 0;
      }
  }

  h1{
      font-size: 28px;
      font-weight: 400;
      margin-bottom: 10px;
      margin-top: 0;
  }

  p.order-num{
      font-size: 14px;
      color: #57595A;
      font-weight: 400;
      margin: 0;
  }

  .address-seperator{
      padding-bottom: 24px;
      border-bottom: 1px solid #DADCDD;
      margin-bottom: 24px;

      ion-grid, ion-col{
          padding-left: 0;
          padding-right: 0;
      }
  }

  .selected-address {
   
      h3{
          font-size: 14px;
          color: #57595A;
          font-weight: 400;
      }

      p{
          font-size: 14px;
          font-weight: 400;
          color: #000;
          line-height: 21px;
          margin: 0;
      }
  }

  h2{
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 4px;
  }

  p.total-items{
      font-size: 12px;
      font-weight: 400;
      color: #989B9C;
      margin-top: 0;
      margin-bottom: 16px;
  }

  .save-for-later{
      width: 100%;
      padding-top: 6px;
      padding-bottom: 14px;

      ion-button{
          width: 100%;
          text-transform: none;

          &::part(native){
              border: 1px solid #000;
              border-radius: 20px;
              padding: 8px 24px;
              height: 34px;
              font-size: 12px;
          }
      }
  }

  .cart-cost-details{
      background-color: #F6F6F8;
      padding: 18px;

      h2{
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 16px;
          color: #000;
      }

      ion-grid{
          padding: 0;

          ion-row{
              padding-bottom: 8px;

              &.with-border{
                  border-bottom: 1px solid #DADCDD;
              }

              &.amount-due{
                  padding-top: 20px;

                  p{
                      font-size: 16px;
                      font-weight: 600;
                      color: #000;
                  }
              }
          }

          ion-col{
              padding: 0;
              display: flex;
              align-items: center;

              &:first-child{
                  justify-content: flex-start;
              }

              &:last-child{
                  justify-content: flex-end;
              }
          }

          p{
              margin: 0;
              font-size: 14px;
              font-weight: 400;
              color: #57595A;
          }
      }
  }

  .place-order{
      background-color: #000;
      width: 100%;
      padding: 24px;
      position: sticky;
      z-index: 99;
      bottom: 0;
      height: 82px;

      ion-button{
          width: 100%;
          text-transform: none;

          &::part(native){
              border: 1px solid #000;
              border-radius: 20px;
              padding: 8px 24px;
              height: 34px;
              font-size: 12px;
          }
      }
  }
}

.custom-select{

  &:hover, &:active{
      background-color: transparent;

      &::part(native) {
          background-color: transparent;

          &:after{
              display: none;
          }
      }
  }

  ion-label{
      font-size: 14px;
      color: #57595A;
      font-weight: 400;
      margin-bottom: 8px;
      display: block;
  }

  .fake-select{
      border: 1px solid #000000;
      border-radius: 44px;
      height: 34px;
      padding: 7px 13px;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      position: relative;

      ion-icon{
          position: absolute;
          top: 8px;
          right: 20px;
      }
  }

  ion-select{
      border: 1px solid #000000;
      border-radius: 44px;
      height: 34px;
      padding: 7px 13px;

      &::part(icon){
          margin-top: 2px;
      }

      &::part(text), &::part(placeholder){
          color: #000;
          font-size: 14px;
          font-weight: 400;
          opacity: 1;
      }
  }
}