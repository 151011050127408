.brands-page {
  
  padding: 0 12px;

  h1 {
    margin: 1rem 0 0;
    font-size: 28px;
    line-height: 33.6px;
  }

  .card {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }

  h3 {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
  }

  .subtitle {
    font-size: 14px;
    line-height: 21px;
    color: #57595A;
  }

  .image {
    object-fit: cover;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    height: 200px;
    min-height: calc(56.25vw - 24px);
    max-height: 80vw;
    border-bottom: 3.42px #000 solid;
    background: #eee;
  }

  .item {
    --ion-grid-column-padding: 0;
    &:last-child {
      margin-bottom: 2rem;
    }
  }
  
  .filters{
    margin-top: 1rem;

    ion-col{
      padding: 0;
      padding-left: .5rem;
      padding-right: .5rem;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    ion-button{
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }
  
  .custom-button{
    width: 100%;
    background-color: transparent;
    color: #000;
    border: 1.5px solid #000000;
    border-radius: 44px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 13px;
    padding-right: 13px;

    ion-icon{
      font-size: 20px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 20px;
    }

    ion-text{
      line-height: 1.2;
      font-weight: 400;
      font-size: 14px;
      flex: 1 1 auto;
      text-align: left;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      min-width: 0;
      min-height: 0;
      overflow: hidden;

      strong{
        margin-top: 2px;
        max-width: none !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis;
        flex: 1;
        min-width: 0;
        min-height: 0;
      }

      span{
        display: inline-block;
        margin-right: 5px;
        width: 47px;
      }
    }

    &.has-filters{
      background-color: #000;
      color: #fff;
    }
  }
}