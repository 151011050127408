ion-breadcrumbs {
  background-color: #ffffff;
  width: 100%;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  flex-wrap: nowrap;

  ion-breadcrumb {
    color: #525252;
    font-size: 14px;

    &.breadcrumb-disabled {
      opacity: 1;
    }
    
    &::part(native) {
      max-width: 177px;
      padding-right: 8px;
      padding-left: 8px;
    }

    &:first-child::part(native){
      padding-left: 0;
    }

    &::part(separator) {
      transform: rotate(73deg);
    }

    &.ios{

      &::part(separator) {
        transform: none;
        margin-left: 10px;
        margin-right: 10px;
        position: relative;
        width: 0;
        &:after{
          content: "—";
          position: absolute;
          top: -5px;
          left: -7px;
          width: 15px;
          height: 2px;
          color: #677483;
        }
      }
    } 

    &:last-child{
      color: #989B9C;

      .breadcrumb-text{
        text-decoration: none;
      }
    }
  }

  .crumb-icon{
    color: #000 !important;
  }

  ion-breadcrumb[href='#'] {
    .breadcrumb-text {
      cursor: default;
    }
  }

  .breadcrumb-text{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: underline;
    font-weight: 400;
  }
}

