div.search-field{
    position: relative;
    margin-bottom: 10px;

    ion-label{
        font-size: 20px !important;
        color: #ACAEAF !important;
    }

    ion-input{
        font-size: 20px;
        font-weight: 400;
        --padding-start: 0;

        input{
            padding-left: 0px !important;
        }
    }

    ion-item::part(native){
        padding-left: 0;
    }

    ion-item{
        --border-color: #000;
    }

    ion-icon{
        position: absolute;
        top: 15px;
        right: 0;
        z-index: 999;
        font-size: 27px;
    }

    .icon-btn{  
    
        ion-icon  {
            position: relative;
            font-size: 27px;
            margin: auto;
            top: 0;
            min-width: 25px;
        }
        .search-button {
            position: absolute;
            top: 40%;
            right: 0;
            color: #858585;
            width:25px !important;
            min-width: 25px;
            height:25px;
        }
        
        .search-field  {
            position: relative;
            font-size: 27px;
            margin: auto;
            top: 0;
            min-width: 25px;
        }
    }
}

.search-page{
    padding-left: 24px;
    padding-right: 24px;
}