.explore-links {
  padding-bottom: 20px;

  .link-image {
    width: 100%;
  }

  .image-link {
    width: 100%;
    text-align: justify;
    padding: 0px 0 20px 0;
    font-size: 18px

  }

  .link-image-container {
    padding: 20px 0;

  }

  .link-icon-container {
    display: flex;
    flex-direction: row;
  }

  .links-section {
    display: block;
    flex-direction: row !important;
    color: #000;
    margin: auto;
  }

  .link-text {
    color: black;
  }
  .link-list {
    margin: auto;
    width: 100%;
  }

  .icon-link {
    text-align: justify;
    padding: 10px 0px;
    font-size:16px;
  }

  ion-icon {
    vertical-align: bottom;
    padding-right: 5px;
  }

  ion-title {
    text-align: left;
    padding: 0;
    font-weight: 400 !important;
    font-size: 32px;
    line-height: 48px;
    color: #ffffff;
    margin-bottom: 8px;
    display: block;
    position: static;
  }
}
