.brands-detail-page {

  .padding-area{
    position: relative;
  }

  h1 {
    margin: 0;
    font-size: 48px;
    line-height: 1.2;
  }

  h2 {
    font-size: 36px;
    line-height: 1.2;
    margin: 0;
  }

  h3 {
    font-size: 28px;
    line-height: 1.2;
    margin: 0;
  }

  h4 {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500;
    margin: 0;
  }

  p {
    font-size: 14px;
    line-height: 25px;
    margin: 1rem 0;
  }

  .cta {
    ion-button {
      margin: 0;
    }
  }

  .subtitle {
    font-size: 14px;
    line-height: 21px;
    color: #57595A;
  }

  .banner {
    min-height: calc(56.25vw - 24px);
    margin: 0.5rem 0 0;

    .video-container {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px; 
      height: 0;
      overflow: hidden; 

      iframe { 
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%; 
      }
    }
  }

  .intro {
    margin: 0;
    padding: 1.5rem 2rem 2.25rem;
    background: #F6F6F6;
  }

  .initials {
    background: #202020;
    color: #fff;
    width: 74px;
    height: 74px;
    border-radius: 100%;
    font-size: 22px;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .member-avatar {
    padding: 0;

    ion-img::part(image) {
      width: 74px;
      height: 74px;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  .member {
    margin: 1rem 0;
  }

  .member-info {
    padding-left: 0.366rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    strong {
      display: block;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.2;
    }

    span {
      display: block;
      font-size: 14px;
      line-height: 17px;
      color: #57595A;
    }
  }

  .products {
    margin: 2.25rem 1.5rem;

    ion-grid {
      --ion-grid-column-padding: 8px;
      --ion-grid-column-padding-xs: 8px;
      margin-left: -8px;
      margin-right: -8px;
    }

    .cta{
      text-align: center;
    }
  }

  .team,
  .contact {
    margin: 0;
    padding: 1.5rem;
    background: #F6F6F6;

    h4 {
      margin-bottom: 1.5rem;
    }
  }

  .team {
    padding-top: 3rem;
  }

  .contact {
    padding-bottom: 4rem;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    // padding-bottom: 150px;

    ion-row {
      margin: 1rem 0;
    }

    a {
      text-decoration: none;
      display: inline;
      border-bottom: 1.5px #000 solid;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  .section {
    padding: 1.5rem;

    .image {
      margin-bottom: 1.5rem;
    }
  }

  .social {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .contact-button {
    margin: 0;
    padding: 1.5rem;
    background: #000;
    position: sticky;
    bottom: 0;
    width: 100%;

    ion-button {
      width: 100%;
      height: auto;
      margin: 0;
      --border-radius: 30px;

      &::part(native) {
        --background: #fff;
        color: #000;
        font-size: 12px;
        line-height: 1.5;
        padding: 0.5rem 0;
        height: auto;
      }
    }
  }

}

.product-tile {
  display: block;
  margin: 0.5rem 0;
  text-decoration: none;
  font-size: 14px;
  line-height: 25.2px;

  span {
    display: inline;
    border-bottom: 1.5px #000 solid;
    line-height: 1;
  }

  p {
    margin: 0;
    border-bottom: 0;
    color: #525252;
    display: block;
  }

  &-first {
    p {
      display: inline-block;
      margin-left: 0.5rem;
    }
  }

  ion-img {
    margin-bottom: 0.75rem;
    background: #f4f4f4;
  }
}